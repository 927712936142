<template>
    <el-dialog title="创建日志" :close-on-click-modal="false" :visible.sync="visible" width="550px">
        <el-form ref="dataForm" :model="dataForm" :rules="dataRule" inline label-width="110px" label-position="right" class="border-form">
            <el-row class="set_border">
                <el-col :span="24"  class="col-set_border">
                    <el-form-item label="Shift No" prop="logNo" class="item">
                        <el-input class="selItemInput" type="input" v-model="dataForm.logNo"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row class="set_border">
                <el-col :span="24" class="col-set_border">
                    <el-form-item label="日期" class="item">
                        <el-date-picker class="selItemInput" value-format="yyyy-MM-dd" v-model="dataForm.logDate"
                            type="date" style="width:300px">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row class="formBtnGroup">
                <el-button v-preventReClick class="btn" type="primary" size="small" @click="dataFormSubmit()">保存</el-button>
            </el-row>
        </el-form>
    </el-dialog>
</template>

<script>
import $common from "@/utils/common.js"
export default {
    name: "workloginfo-add-or-update",
    data() {
        return {
            visible: true,
            dataForm: {
                workNo: $common.getItem("workNo"),
                recNo: '',
                logNo: '',
                projectName: "",
                projectPlace: "",
                logDate: '',
                weather: '',
                engineer: '',
                leader: '',
                workLogKind: ''
            },
            dataRule: {
				logNo: [
					{ required: true, message: " ", trigger: "blur" }
				],
            }
        }
    },
    components: {

    },
    methods: {
        getCurrentTime() {
            //获取当前时间并打印
            var _this = this;
            let yy = new Date().getFullYear();
            let mm = (new Date().getMonth() + 1) < 10 ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1);
            let dd = (new Date().getDate()) < 10 ? '0' + (new Date().getDate()) : (new Date().getDate());
            let hh = new Date().getHours();
            let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
            let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
            return yy + '-' + mm + '-' + dd;
        },
        init() {
            this.dataForm = {
                workNo: $common.getItem("workNo"),
                recNo: '',
                logNo: '',
                projectName: '',
                projectPlace: "",
                logDate: '',
                weather: '',
                engineer: '',
                leader: '',
                workLogKind: '0'
            }
            this.dataForm.logDate = this.getCurrentTime();
            this.resetInfo()
            this.$nextTick(() => {
                this.visible = true;
            })
        },
        getDetail() {
            this.$http({
                url: this.$store.state.httpUrl + "/business/workloginfo/info/" + this.dataForm.recNo,
                method: "get",
                params: {},
            }).then(({
                data
            }) => {
                if (data && data.resultCode === 200) {
                    this.dataForm = data.body;
                }
            });
        },
        getPresetInfo() {
            this.$http({
                url: this.$store.state.httpUrl + "/business/worklogpreset/presetInfo/" + $common.getItem("workNo"),
                method: "get"
            }).then(({ data }) => {
                if (data && data.resultCode === 200) {
                    this.dataForm.logNo = data.body.logNo;
                    this.dataForm.projectName = data.body.projectName;
                    this.dataForm.projectPlace = data.body.projectPlace;
                    this.dataForm.leader = data.body.leader;
                    this.dataForm.logNo = data.body.logNo;
                    this.dataForm.engineer = data.body.engineer;
                }
            });
        },
        changeLine(str) {
            return str.replaceAll('\r', '\n')
        },
        resetInfo() {
            this.getPresetInfo()
        },
        dataFormSubmit() {
            if(this.dataForm.logNo == undefined || this.dataForm.logNo == '') {
                this.$message.error("请填写Shift No");
                return;
            }

            this.$refs["dataForm"].validate(valid => {
                if (valid) {
                    let _url = "/business/workloginfo/save";
                    this.$http({
                        url: this.$store.state.httpUrl + _url,
                        method: "post",
                        data: this.$http.adornData(this.dataForm)
                    }).then(({
                        data
                    }) => {
                        if (data && data.resultCode === 200) {
                            this.$message({
                                message: "操作成功",
                                type: "success",
                                duration: 1500,
                                onClose: () => {
                                    this.visible = false;
                                    this.$parent.getDataList();
                                }
                            });
                        }
                    });
                }
            });
        },
    }
}
</script>

<style lang="scss" scoped="scoped">
.selItemInput.descAreaLg {
    width: 718px !important;
}

.item::v-deep .el-form-item__label {
    color: #00428E !important;
    margin-right: 10px !important;
    font-weight: bold !important;
    background-color: #E1F1FF;
    white-space: nowrap !important;
    border-right: solid #dadada 1px;
}

.border-form .el-row.set_border {
    border-bottom: solid #dadada 1px;
    border-left: solid #dadada 1px;
    border-right: solid #dadada 1px;
}

.border-form .el-row.set_border:first-child {
    border-top: solid #dadada 1px;
}

::v-deep .el-form-item {
    //   display: flex !important;
    align-items: stretch !important;
    margin-bottom: 0px !important;
}
</style>
