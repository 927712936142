<template>
	<!-- 监理周报 -->
	<div class="mod-config">
		<!-- <el-card body-style="padding-bottom: 0;">
			<el-form ref="form" :model="form" inline label-width="80px" label-position="left">
				<el-row>
					<el-col :span="20">
						<el-form-item label="Shift No">
							<el-input class="selItemInput" v-model="logNo" clearable></el-input>
						</el-form-item>
						<el-form-item label="日志时间">
							<el-date-picker class="selItemInput" v-model="logTime" type="daterange"
								value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期"
								end-placeholder="结束日期" clearable>
							</el-date-picker>
						</el-form-item>
					</el-col>
					<el-col :span="4" class="selBtnGroup">
						<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain
							@click="resetFormData()">重置</el-button>
						<el-button v-preventReClick class="primaryPlainBtn" type="primary"
							@click="getDataList()">检索</el-button>
					</el-col>
				</el-row>
			</el-form>
		</el-card> -->
		<el-card body-style="padding-bottom: 0;" style="margin-top: -5px;">
			<div class="title">监理日志</div>
			<div class="line"></div>
			<el-row style="margin-bottom: 5px;">
				<el-col :span="pageSpan">
					<el-button v-preventReClick class="btn" type="primary" size="small" @click="addHandle()">新增</el-button>
					<el-button v-preventReClick class="btn" type="primary" size="small" :disabled="disabledBatchDelete"
						@click="deleteBatchHandle()">删除</el-button>
					<!-- <el-button v-preventReClick class="btn" type="primary" size="small"
						@click="uploadHandle()">上传</el-button> -->
					<el-button v-preventReClick class="btn" type="primary" size="small"
						@click="presetHandle()">设置</el-button>
					<el-button v-preventReClick class="btn" type="primary" size="small" :disabled="disabledBatchDelete"
						@click="zipDownloadWorkLog()">下载</el-button>
				</el-col>
				<!-- <el-col :span="24" style="margin-top: 15px">
					<el-alert title="提醒：完成本日巡检、报验等工作可生成监理日志数据" type="warning" effect="dark" :closable="false">
					</el-alert>
				</el-col> -->
			</el-row>
		</el-card>
		<div>
			<el-card style="margin-top: -3px; height: 595px;" body-style="height:555px; padding: 0;">
				<el-row style="height: 100%; margin-bottom: 10px;">
					<el-col :span="24" style="height: 100%;">
						<div class="box">
							<el-table height="535" :row-class-name="tableRowClassName" :row-style="selectRowBackground"
								class="dataListTable" :data="dataList" header-align="center" border
								style="width: 100%; margin-bottom: 20px;" header-cell-class-name="dataListTHeader"
								:cell-style="{ padding: '0px' }" @selection-change="handleSelectionChange">
								<el-table-column header-align="center" align="center" width="80" type="selection"
									label=" "></el-table-column>
								<el-table-column type="index" label="NO." width="100" align="center">
									<template slot-scope="scope">{{ (pageIndex2 - 1) * limit + scope.$index + 1 }}</template>
								</el-table-column>
								<el-table-column prop="logNo" label="Shift No" align="center">
									<template slot-scope="scope">
											<el-button v-preventReClick type="text" size="small"
												@click="viewPdf(scope.row.filePath, scope.row.fileUrl, scope.row.logNo)">{{
													scope.row.logNo }}</el-button>
										</template>
								</el-table-column>
								<!-- <el-table-column prop="projectPlace" label="工程地点" align="center">
						</el-table-column> -->
								<el-table-column prop="logDate" label="日期" width="200" align="center">
								</el-table-column>
								<el-table-column label="确认状态" width="100" align="center">
									<template slot-scope="scope">
										<span v-if="scope.row.refirmFlg === '1'">已确认</span>
										<span v-else>未确认</span>
									</template>
								</el-table-column>
								<el-table-column prop="refirmMan" label="确认人" width="200" align="center">
								</el-table-column>
								<el-table-column prop="refirmTime" label="确认时间" width="110" align="center">
								</el-table-column>
								<!-- <el-table-column prop="weather" label="天气" width="120" align="center">
						</el-table-column>
						<el-table-column prop="engineer" label="监理工程师" width="120" align="center">
						</el-table-column>
						<el-table-column prop="leader" label="监理组长" width="120" align="center">
						</el-table-column> -->
								<el-table-column label="" width="200" fixed="right" align="left">
									<template slot-scope="scope">
										<el-button v-preventReClick type="text" size="small"
											v-if="scope.row.refirmFlg !== '1'"
											@click="updateHandle(scope.row.recNo)">修改</el-button>
										<el-button v-preventReClick type="text" size="small"
											@click="refirmHandle(scope.row.recNo)"
											v-if="scope.row.refirmFlg !== '1'">确认</el-button>
										<el-button v-preventReClick type="text" size="small"
											v-if="scope.row.refirmFlg !== '1'"
											@click="uploadHandle(scope.row.recNo)">上传</el-button>
										<!-- <el-button v-preventReClick type="text" size="small"
									@click="deleteHandle(scope.row.recNo)">删除</el-button> -->
										<el-button v-preventReClick type="text" size="small"
											@click="cancelRefirmHandle(scope.row.recNo)"
											v-if="scope.row.refirmFlg === '1'">解除确认</el-button>
										<el-button v-preventReClick type="text" size="small"
											@click="downloadFile(scope.row.fileUrl, scope.row.logNo)"
											v-if="scope.row.fileUrl">下载</el-button>
									</template>
								</el-table-column>
							</el-table>
						</div>
						<el-pagination class="my-pagination" @size-change="sizeChangeHandle"
							@current-change="currentChangeHandle" :current-page="page" :page-sizes="[10, 20, 50, 100]"
							:page-size="limit" :total="totalCount" layout="total, sizes, prev, pager, next, jumper">
						</el-pagination>
					</el-col>
				</el-row>
			</el-card>
		</div>
		<add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="dataList"></add-or-update>
		<user-preset v-if="userPresetVisible" ref="userPreset"></user-preset>
		<work-upload v-if="uploadVisible" ref="workUpload"></work-upload>
		<work-add v-if="addVisible" ref="workAdd" @refreshDataList="dataList"></work-add>
		<work-update v-if="updateVisible" ref="workUpdate" @refreshDataList="dataList"></work-update>
		<work-refirm v-if="refirmVisible" ref="workRefirm" @refreshDataList="dataList"></work-refirm>
		<view-pdf v-if="viewPdfVisible" ref="viewPdf" @refreshDataList="dataList"></view-pdf>
	</div>
</template>
<script>
import AddOrUpdate from './workloginfo-add-or-update'
import WorkAdd from './workloginfo-add.vue'
import WorkUpdate from './workloginfo-update.vue'
import UserPreset from './workloginfo-preset'
import WorkUpload from './workloginfo-upload.vue'
import workRefirm from './workloginfo-refirm'
import ViewPdf from "../projectInfo/viewPdf.vue"
import $common from "@/utils/common.js"
export default {
	data() {
		return {
			viewPdfVisible: false,
			refirmVisible: false,
			showSearch: false,
			showDownload: false,
			showAdd: false,
			showEdit: false,
			showDelete: false,
			showCheck: false,
			showArchive: false,
			showRowDownload: false,
			pageSpan: 12,
			addOrUpdateVisible: false,
			userPresetVisible: false,
			uploadVisible: false,
			exportVisible: false,
			auditVisible: false,
			enclosureVisible: false,
			disabledBatchDelete: true,
			addVisible: false,
			updateVisible: false,
			form: {},
			logNo: '',
			limit: 20,
			// 显示序号
			pageIndex2: 1,
			page: 1,
			totalCount: 0,
			dataList: [],
			logTime: [],
			tableSelVal: []

		};
	},
	components: {
		AddOrUpdate,
		WorkAdd,
		WorkUpdate,
		UserPreset,
		WorkUpload,
		workRefirm,
		ViewPdf
	},
	mounted() {
		this.getDataList();
	},
	methods: {
		getCurrentDate() {
			const today = new Date();
			let year = today.getFullYear();
			let month = (today.getMonth() + 1).toString().padStart(2, '0');
			let day = today.getDate().toString().padStart(2, '0');
			return `${year}${month}${day}`;
		},
		enclosureHandle(id) {
			this.enclosureVisible = true
			this.$nextTick(() => {
				this.$refs.enclosureAddOrUpdate.init(id)
			})
		},
		selectFile(url) {
			window.open(url);
		},
		//重置
		resetFormData() {
			this.pageIndex = 1;
			this.getDataList();
		},
		viewPdf(filePath, fileUri, filename) {
			// pdf和doc文件，需要pdf在线预览，其他类型文件直接下载
			let suffixType = fileUri.substr(fileUri.lastIndexOf(".") + 1)
			if(suffixType != '' && (suffixType === 'pdf' || suffixType === 'doc' || suffixType === 'docx')) {
				// $common.viewFile(filePath)
				this.viewPdfVisible = true;
				this.$nextTick(() => {
					this.$refs.viewPdf.init(filename, filePath)
				})
			} else {
				filename = this.getSuffix(fileUri, filename)
				this.getBlob(fileUri, (blob) => {
					this.saveAs(blob, filename)
				})
			}
		},
		downloadFile(fileUri, filename) {
			filename = this.getSuffix(fileUri, filename)
			this.getBlob(fileUri, (blob) => {
				this.saveAs(blob, filename)
			})
		},
		getSuffix(url, filename) {
			let lastIndex = url.lastIndexOf(".");
			return filename + url.substr(lastIndex)
		},
		getBlob(url, cb) {
			var xhr = new XMLHttpRequest()
			xhr.open('GET', url, true)
			xhr.responseType = 'blob'
			xhr.onload = function () {
				if (xhr.status === 200) {
					cb(xhr.response)
				}
			}
			xhr.send()
		},
		saveAs(blob, filename) {
			if (window.navigator.msSaveOrOpenBlob) {
				navigator.msSaveBlob(blob, filename)
			}
			else {
				var link = document.createElement('a')
				var body = document.querySelector('body')

				link.href = window.URL.createObjectURL(blob)
				link.download = filename

				link.style.display = 'none'
				body.appendChild(link)

				link.click()
				body.removeChild(link)
				window.URL.revokeObjectURL(link.href)
			}
		},
		getDataList() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/workloginfo/list",
				method: 'get',
				params: this.$http.adornParams({
					limit: this.limit,
					page: this.page,
					logNo: this.logNo,
					workNo: $common.getItem("workNo"),
					beginTime: this.logTime[0] ? this.logTime[0] : "",
					endTime: this.logTime[1] ? this.logTime[1] : "",
				})
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.dataList = data.body.records;
					this.totalCount = data.body.totalCount;
					this.pageIndex2 = this.page
				} else {
					this.dataList = []
					this.totalCount = 0
					this.pageIndex2 = 1
				}
			})
		},
		refirmHandle(id) {
			this.refirmVisible = true
			this.$nextTick(() => {
				this.$refs.workRefirm.init(id)
			})
		},
		cancelRefirmHandle(id) {
			this.$confirm(`确定对选中的数据进行解除确认操作?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + "/business/workloginfo/cancelworkLogRefirm/" + id,
					method: "post",
					data: {},
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					}
				});
			})
		},
		//删除
		deleteHandle(id) {
			this.$confirm(`确定对选中的数据进行删除操作?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + "/business/workloginfo/delete/" + id,
					method: "post",
					data: {},
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					}
				});
			})
		},
		deleteBatchHandle() {
			this.$confirm(`确定对选中的数据进行删除操作？`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let recIds = this.tableSelVal.map((item) => item.recNo);
				this.$http({
					url: this.$store.state.httpUrl + `/business/workloginfo/deleteBatch`,
					method: 'post',
					data: this.$http.adornData({
						records: recIds
					})
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					}
				});
			})
				.catch(() => { })
		},
		siteContentViewHeight() {
			var height = this.$store.state.common.documentClientHeight - 190;
			return { height: height + 'px' }
		},
		handleSelectionChange(val) {
			this.tableSelVal = val;
			if (val.length > 0) {
				this.disabledBatchDelete = false;
			} else {
				this.disabledBatchDelete = true;
			}
		},
		tableRowClassName({ row, rowIndex }) {
			let color = ''
			for (let item of this.tableSelVal) {
				if (item.id === row.id) color = 'table-SelectedRow-bgcolor'
			}
			return color
		},
		// 每页数
		sizeChangeHandle(val) {
			this.limit = val
			this.page = 1
			this.getDataList()
		},
		// 当前页
		currentChangeHandle(val) {
			this.page = val
			this.getDataList()
		},
		addOrUpdateHandle(id) {
			this.addOrUpdateVisible = true;
			this.$nextTick(() => {
				this.$refs.addOrUpdate.init(id);
			})
		},
		addHandle() {
			this.addVisible = true;
			this.$nextTick(() => {
				this.$refs.workAdd.init();
			})
		},
		updateHandle(id) {
			let _url = "/business/workloginfo/refreshDetailList/" + id;
			this.$http({
				url: this.$store.state.httpUrl + _url,
				method: "post",
				data: []
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					// 跳转
					this.$router.push({ name: 'workLogInfoUpdate', query: { recNo: id } });
				}
			});
		},
		uploadHandle(id) {
			this.uploadVisible = true;
			this.$nextTick(() => {
				this.$refs.workUpload.init(id);
			})
		},
		presetHandle() {
			this.userPresetVisible = true;
			this.$nextTick(() => {
				this.$refs.userPreset.init();
			})
		},
		zipDownloadWorkLog() {
			var fileName = "监理日志" + this.getCurrentDate() + ".zip"
			let recIds = this.tableSelVal.map((item) => item.recNo);
			this.$http({
				url: this.$store.state.httpUrl + "/business/workloginfo/zipDownloadWorkLog",
				method: "POST",
				data: this.$http.adornData({
					records: recIds
				})
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.$message({
						message: '操作成功',
						type: 'success',
						duration: 1500,
						onClose: () => {
							this.global.downLoadFile(data.body, fileName);
						}
					})
				}
			});
		},
		tableRowClassName({ row, rowIndex }) {
			return $common.tableRowClassName({ row, rowIndex });
		},
		selectRowBackground({ row }) {
			const checkIdList = this.tableSelVal.map((item) => item.recNo);
			if (checkIdList.includes(row.recNo)) {
				return {
					backgroundColor: "#fae8cb",
				};
			}
		},
	}
};
</script>
<style lang="scss" scoped="scoped">
.selItemText {
	font-size: 14px;
	color: #1c1c1c;
	display: inline-block;
	width: 80px;
}

.my-pagination {
	margin-right: 20px !important;
}

// 设置表头样式
::v-deep(.el-table th.el-table__cell) {
	// background-color: #d3e2f4 !important;
	font-size: 15px;
	padding: 2px 0;
}
</style>
